import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { AboutType } from "../../types/strapi-types";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function About() {
	const { data } = useStrapiFetch<AboutType>("/api/about?populate=*");

	if (!data) {
		return null;
	}

	const { text, image } = data;

	return (
		<div
			id="about"
			className="md:scroll-mt-20 scroll-mt-14 container mx-auto px-4 flex gap-8 md:flex-row flex-col justify-center items-center py-8"
		>
			<div className="flex-1">
				<h2 className="md:text-5xl text-3xl archivo-black-font uppercase">
					ABOUT THE COMPANY
				</h2>

				<ReactMarkdown className="text-base mt-8 first:[&>p]:mt-0 [&>p]:mt-2">
					{text}
				</ReactMarkdown>
			</div>

			<div className="flex-1 flex justify-center">
				<img
					src={getStrapiImageUrl(image.url)}
					alt={image.alternativeText || image.name}
					className="object-contain"
				/>
			</div>
		</div>
	);
}
