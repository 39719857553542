import Slick, { CustomArrowProps, Settings } from "react-slick";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { SliderItemType } from "../../types/strapi-types";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import "./slider.css";

const settings: Settings = {
	dots: true,
	infinite: true,
	speed: 600,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 10000,
	draggable: false,
	fade: true,
	pauseOnHover: false,
	nextArrow: <Arrow />,
	prevArrow: <Arrow left />,
	customPaging: () => (
		<button className="block w-4 h-4 rounded-full border-2 border-white duration-200 cursor-pointer hover:bg-white/30" />
	),
	dotsClass:
		"slick-dots absolute z-10 left-1/2 -translate-x-1/2 bottom-4 w-full h-4 !flex justify-center items-center [&>li]:h-4 [&>li]:w-4 gap-2",
};

function Arrow(props: CustomArrowProps & { left?: boolean }) {
	const { style, onClick, left } = props;
	return (
		<button
			className={`${left ? "left-4" : "right-4"} 
			absolute z-10 top-1/2 -translate-y-1/2 w-8 h-8
			cursor-pointer rounded-full
			bg-black/20 hover:bg-black/60 duration-200`}
			style={{ ...style }}
			onClick={onClick}
		>
			{left ? (
				<FontAwesomeIcon
					icon={"fa-solid fa-chevron-left" as any}
					className="w-5 h-5 text-white absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
				/>
			) : (
				<FontAwesomeIcon
					icon={"fa-solid fa-chevron-right" as any}
					className="w-5 h-5 text-white absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
				/>
			)}
		</button>
	);
}

export function Slider() {
	const { data } = useStrapiFetch<SliderItemType[]>(
		"/api/slider-items?populate=*"
	);

	if (!data) {
		return null;
	}

	return (
		<Slick {...settings} className="overflow-hidden">
			{data.map((item, i) => (
				<div
					key={`slider-item-${i}`}
					className="relative w-full h-[60vh] min-h-[700px] scale-105"
				>
					<img
						src={getStrapiImageUrl(item.image.url)}
						alt={item.image.alternativeText || item.image.name}
						className="slider-bg absolute top-0 left-0 w-full h-full object-cover"
					/>
					<img
						src={getStrapiImageUrl(item.text.url)}
						alt={item.text.alternativeText || item.text.name}
						className="slider-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-contain w-full max-w-sm px-4"
					/>
				</div>
			))}
		</Slick>
	);
}
