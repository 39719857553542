import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { ContactType } from "../../types/strapi-types";
import Slick, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import { useRef, useState } from "react";
import { ContactForm } from "./contact-form";

const settings: Settings = {
	dots: false,
	arrows: false,
	infinite: true,
	speed: 600,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: false,
	adaptiveHeight: true,
	touchMove: false,
};

export function Contact() {
	const { data } = useStrapiFetch<ContactType>("/api/contact?populate=*");

	const [slideIndex, setSlideIndex] = useState(0);
	const sliderRef = useRef<Slick>(null);
	settings.beforeChange = (current, next) => setSlideIndex(next);

	if (!data) {
		return null;
	}

	const { address, phone, email, footer } = data;

	return (
		<div
			id="contact"
			className="md:scroll-mt-20 scroll-mt-14 bg-black text-white"
		>
			<div className="container mx-auto px-4 py-8">
				<h2 className="md:text-5xl text-3xl archivo-black-font uppercase text-center">
					CONTACT US
				</h2>

				<div className="flex justify-center items-center mt-8">
					<button
						className={`text-sm font-bold uppercase md:py-5 py-3 md:px-16 px-8 rounded-t border-b-2 border-[#555555] text-[#808080]/80 hover:text-white hover:border-[#fb6884] duration-200 mx-1 ${
							slideIndex === 0
								? "!bg-[#bf2643] !text-white !border-[#fb6884]"
								: ""
						}`}
						onClick={() => sliderRef.current?.slickGoTo(0)}
					>
						CONTACT INFO
					</button>

					<button
						className={`text-sm font-bold uppercase md:py-5 py-3 md:px-16 px-8 rounded-t border-b-2 border-[#555555] text-[#808080]/80 hover:text-white hover:border-[#fb6884] duration-200 mx-1 ${
							slideIndex === 1
								? "!bg-[#bf2643] !text-white !border-[#fb6884]"
								: ""
						}`}
						onClick={() => sliderRef.current?.slickGoTo(1)}
					>
						SERVICE INQUIRY
					</button>
				</div>

				<Slick
					{...settings}
					ref={sliderRef}
					className="overflow-hidden h-auto !select-text [&_.slick-list]:duration-200"
				>
					<div>
						<div className="flex md:flex-row flex-col justify-center items-center text-center mt-8 -m-4">
							<div className="md:w-1/3 p-4">
								<FontAwesomeIcon
									icon={"fa-solid fa-house" as any}
									className="text-4xl bg-[#bf2643] p-8 rounded-full"
								/>

								<h3 className="text-base archivo-black-font uppercase mt-6">
									ADDRESS
								</h3>
								<p className="text-sm">{address}</p>
							</div>

							<div className="md:w-1/3 p-4">
								<FontAwesomeIcon
									icon={"fa-solid fa-phone" as any}
									className="text-4xl bg-[#bf2643] p-8 rounded-full relative z-10"
								/>
								<h3 className="text-base archivo-black-font uppercase mt-6">
									PHONE
								</h3>
								<p className="text-sm">
									<a href={`tel:${phone}`}>{phone}</a>
								</p>
							</div>

							<div className="md:w-1/3 p-4">
								<FontAwesomeIcon
									icon={"fa-solid fa-envelope" as any}
									className="text-4xl bg-[#bf2643] p-8 rounded-full"
								/>
								<h3 className="text-base archivo-black-font uppercase mt-6">
									EMAIL
								</h3>
								<p className="text-sm">
									<a href={`mailto:${email}`}>{email}</a>
								</p>
							</div>
						</div>
					</div>

					<div>
						<ContactForm />
					</div>
				</Slick>
			</div>

			<footer className="text-base p-4 text-center">{footer}</footer>
		</div>
	);
}
