import { useEffect, useState } from "react";
import { NavigationLinks } from "./navigation-links";

export function NavigationMenu() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		const body = document.querySelector("body");

		if (!body) {
			return;
		}

		if (isMenuOpen) {
			body.classList.add("overflow-hidden");
		} else {
			body.classList.remove("overflow-hidden");
		}
	}, [isMenuOpen]);

	return (
		<>
			<button
				className="relative z-50"
				onClick={() => setIsMenuOpen((prev) => !prev)}
			>
				<div
					className={`relative w-6 h-6 [&>*]:h-0.5 [&>*]:w-full [&>*]:absolute [&>*]:right-0 [&>*]:rounded [&>*]:transition-transform ${
						isMenuOpen ? "[&>*]:bg-white" : "[&>*]:bg-black"
					}`}
				>
					<div
						className={`top-0 ${
							isMenuOpen ? "rotate-45 top-1/2 -translate-y-1/2" : ""
						}`}
					/>
					<div
						className={`top-1/2 -translate-y-1/2 ${
							isMenuOpen ? "scale-0" : ""
						}`}
					/>
					<div
						className={`!w-[80%] bottom-0 ${
							isMenuOpen ? "!w-full -rotate-45 top-1/2 -translate-y-1/2" : ""
						}`}
					/>
				</div>
			</button>

			<div
				className={`fixed ${
					isMenuOpen ? "top-0" : "-top-[100vh]"
				} left-0 w-screen h-screen py-12 z-40 transition-all`}
			>
				<div
					className="bg-[#212020] absolute left-0 top-0 
						w-full h-full z-20"
				/>

				<div
					className="absolute left-0 top-0 w-screen h-screen z-10"
					onClick={() => setIsMenuOpen((prev) => !prev)}
				/>

				<nav className="z-30 relative w-full h-full py-4 flex flex-col items-center justify-center gap-4 [&_a]:text-2xl [&_a]:text-white overflow-auto">
					<NavigationLinks closeMenu={() => setIsMenuOpen((prev) => !prev)} />
				</nav>
			</div>
		</>
	);
}
