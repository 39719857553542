import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { TeamItemType, TeamType } from "../../types/strapi-types";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function Team() {
	const { data: itemsData } = useStrapiFetch<TeamItemType[]>(
		"/api/team-items?populate=*"
	);

	const { data } = useStrapiFetch<TeamType>("/api/team?populate=*");

	if (!itemsData || !data) {
		return null;
	}

	const { image } = data;

	return (
		<div
			id="team"
			className="md:scroll-mt-20 scroll-mt-14 container mx-auto px-4 py-8"
		>
			<h2 className="md:text-5xl text-3xl archivo-black-font uppercase text-center">
				OUR TEAM
			</h2>

			<div className="my-4 grid md:grid-cols-2 grid-cols-1 -mx-4">
				{itemsData.map((item, i) => (
					<div key={`team-item-${i}`} className="p-4">
						<h3 className="text-lg archivo-black-font uppercase">
							{item.title}
						</h3>
						<ReactMarkdown className="text-base mt-2 first:[&>p]:mt-0 [&>p]:mt-2">
							{item.text}
						</ReactMarkdown>
					</div>
				))}
			</div>

			<div className="flex justify-center">
				<img
					src={getStrapiImageUrl(image.url)}
					alt={image.alternativeText || image.name}
					className="object-contain"
				/>
			</div>
		</div>
	);
}
