const links = [
	{
		href: "/#about",
		text: "ABOUT THE COMPANY",
	},
	{
		href: "/#how-we-work",
		text: "HOW WE WORK",
	},
	{
		href: "/#services",
		text: "SERVICES",
	},
	{
		href: "/#team",
		text: "OUR TEAM",
	},
	{
		href: "/#contact",
		text: "CONTACT US",
	},
];

export function NavigationLinks({ closeMenu }: { closeMenu?: () => void }) {
	return (
		<>
			{links.map((link) => (
				<div
					key={link.href}
					className="h-12 flex items-center justify-center"
					onClick={closeMenu}
				>
					<a
						href={link.href}
						className="px-2 hover:text-[#d8455b] font-bold uppercase duration-200 relative"
					>
						{link.text}
					</a>
				</div>
			))}
		</>
	);
}
