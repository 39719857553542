import { useEffect } from "react";

export function useParallax(ref: React.RefObject<HTMLElement>) {
	useEffect(() => {
		handleScroll();

		function handleScroll() {
			if (!ref.current) {
				return;
			}

			const windowHeight = window.innerHeight;
			const scrollY = window.scrollY;

			const { top, height } = ref.current.getBoundingClientRect();
			const offsetTop = top + scrollY;

			if (
				windowHeight > top &&
				top + height > 0 &&
				windowHeight < top + height
			) {
				const relativeY = scrollY - offsetTop;

				const translateY = relativeY * 0.3;

				requestAnimationFrame(
					() => (ref.current!.style.top = `${translateY}px`)
				);
			}
		}

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [ref]);
}
