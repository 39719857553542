import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function Logo({
	url,
	alternativeText,
	name,
}: {
	url: string;
	alternativeText?: string;
	name: string;
}) {
	return (
		<a href="/#" className="block md:w-28 w-20 h-full relative">
			<img
				src={getStrapiImageUrl(url)}
				alt={alternativeText || name}
				className="absolute top-0 left-0 w-full h-full object-contain py-1"
			/>
		</a>
	);
}
