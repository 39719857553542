import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { HowWeWorkType } from "../../types/strapi-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slick, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import { useRef, useState } from "react";

const settings: Settings = {
	dots: false,
	arrows: false,
	infinite: true,
	speed: 600,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: false,
	adaptiveHeight: true,
	touchMove: false,
};

export function HowWeWork() {
	const { data } = useStrapiFetch<HowWeWorkType[]>(
		"/api/how-we-works?populate=*&sort=order:asc"
	);

	const [slideIndex, setSlideIndex] = useState(0);
	const sliderRef = useRef<Slick>(null);
	settings.beforeChange = (current, next) => setSlideIndex(next);

	if (!data) {
		return null;
	}

	return (
		<div id="how-we-work" className="md:scroll-mt-20 scroll-mt-14 pt-8">
			<h2 className="md:text-5xl text-3xl archivo-black-font uppercase text-center px-4">
				HOW WE WORK
			</h2>

			<div className="flex flex-wrap mt-8 justify-center items-center">
				{data.map((item, i) => (
					<button
						key={`how-we-work-${i}`}
						className={`p-6 hover:text-[#d8455b] duration-200 
							${slideIndex === i ? "text-[#d8455b]" : ""}`}
						onClick={() => sliderRef.current?.slickGoTo(i)}
					>
						<div className="flex justify-center items-center">
							<FontAwesomeIcon icon={item.icon as any} className="text-xl" />
						</div>
						<p className="text-sm uppercase mt-4">{item.title}</p>
					</button>
				))}
			</div>

			<div className="bg-[#f6f6f6] relative">
				<div className="absolute top-0 left-0 w-full h-2 bg-[linear-gradient(to_top,_#f6f6f6,_#ececec)]" />
				<div className="absolute top-0 left-1/2 -translate-x-1/2 border-transparent border-t-white border-[1.25rem] border-l-[2rem] border-r-[2rem]" />

				<div className="container mx-auto px-4 py-20">
					<Slick
						{...settings}
						ref={sliderRef}
						className="overflow-hidden h-auto !select-text [&_.slick-list]:duration-200"
					>
						{data.map((item, i) => (
							<div key={`how-we-work-slider-${i}`}>
								<h3 className="text-lg archivo-black-font text-[#bf2643]">
									{item.title}
								</h3>
								<ReactMarkdown className="mt-2 text-base first:[&>p]:mt-0 [&>p]:mt-2">
									{item.text}
								</ReactMarkdown>
							</div>
						))}
					</Slick>
				</div>
			</div>
		</div>
	);
}
