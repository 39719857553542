import { useAppErrorStore } from "./hooks/use-app-error-store";
import { useAppLoadingStore } from "./hooks/use-app-loading-store";
import { Error } from "./components/error/error";
import { Loader } from "./components/loader/loader";
import { Header } from "./components/header/header";
import { Slider } from "./components/slider/slider";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Welcome } from "./components/welcome/welcome";
import { About } from "./components/about/about";
import { HowWeWork } from "./components/how-we-work/how-we-work";
import { Services } from "./components/services/services";
import { Team } from "./components/team/team";
import { Contact } from "./components/contact/contact";

library.add(fas, far, fab);

export default function App() {
	const { isError } = useAppErrorStore();
	const { tags } = useAppLoadingStore();

	const isLoading = tags.length > 0;

	return (
		<div className="flex flex-col">
			{isError && <Error />}

			{isLoading && <Loader />}

			<Header />

			<main className="flex-1 overflow-x-hidden">
				<Slider />

				<Welcome />

				<About />

				<HowWeWork />

				<Services />

				<Team />

				<Contact />
			</main>
		</div>
	);
}
