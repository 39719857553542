import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { WelcomeType } from "../../types/strapi-types";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function Welcome() {
	const { data } = useStrapiFetch<WelcomeType>("/api/welcome?populate=*");

	if (!data) {
		return null;
	}

	const { title, text, image } = data;

	return (
		<div className="bg-[#d8455b] border-t border-white">
			<div className="container mx-auto px-4 flex gap-8 md:flex-row flex-col justify-center items-center py-8">
				<div className="flex-1 flex justify-center">
					<img
						src={getStrapiImageUrl(image.url)}
						alt={image.alternativeText || image.name}
						className="object-contain"
					/>
				</div>

				<div className="flex-1">
					<ReactMarkdown className="text-white md:text-3xl text-xl first:[&>p]:mt-0 [&>p]:mt-2">
						{text}
					</ReactMarkdown>

					<h2 className="md:text-5xl text-3xl mt-4 archivo-black-font text-[#ffcf12] uppercase">
						{title}
					</h2>
				</div>
			</div>
		</div>
	);
}
