import { useRef } from "react";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { ServiceItemType, ServiceType } from "../../types/strapi-types";
import { useParallax } from "../../hooks/use-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import "./services.css";

export function Services() {
	const { data: itemsData } = useStrapiFetch<ServiceItemType[]>(
		"/api/service-items?populate=*&sort=order:asc"
	);

	const { data } = useStrapiFetch<ServiceType>("/api/service?populate=*");

	const bgRef = useRef<HTMLImageElement>(null);
	useParallax(bgRef);

	if (!itemsData || !data) {
		return null;
	}

	const { bg } = data;

	return (
		<div id="services" className="md:scroll-mt-20 scroll-mt-14">
			<div className="relative text-white bg-black overflow-hidden">
				<img
					ref={bgRef}
					src={getStrapiImageUrl(bg.url)}
					alt={bg.alternativeText || bg.name}
					className="absolute top-0 left-0 w-full h-[200%] object-cover md:block hidden"
				/>

				<div className="relative z-10 container px-4 py-8 mx-auto">
					<p className="md:text-xl text-base uppercase text-center">
						CREATING AWESOMENESS
					</p>
					<h2 className="md:text-5xl text-3xl mt-1 archivo-black-font uppercase text-center">
						SERVICES WE PROVIDE
					</h2>

					<div className="w-24 h-px bg-white mx-auto my-8 relative">
						<div className="w-3 h-3 bg-black border border-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
					</div>

					<div className="relative">
						<div className="bg-[#bf2643]/70 grid md:grid-cols-2 grid-cols-1">
							{itemsData.map((item, i) => (
								<div
									key={`service-item-${i}`}
									className="service-item relative md:p-16 p-8"
								>
									<div
										className={`flex items-center justify-start 
											${i % 2 === 1 ? "flex-row-reverse" : ""}`}
									>
										<FontAwesomeIcon
											icon={item.icon as any}
											className="text-5xl"
										/>

										<div className={i % 2 === 1 ? "mr-4" : "ml-4"}>
											<h3
												className={`text-lg archivo-black-font uppercase 
													${i % 2 === 1 ? "text-right" : "text-left"}`}
											>
												{item.title}
											</h3>
											<div
												className={`w-10 h-px mt-4 bg-white 
													${i % 2 === 1 ? "ml-auto" : "mr-auto"}`}
											/>
										</div>
									</div>

									<ReactMarkdown className="mt-8 text-base first:[&>p]:mt-0 [&>p]:mt-2">
										{item.text}
									</ReactMarkdown>

									<div className="separator absolute bottom-0 left-1/2 -translate-x-1/2 h-px w-[75%] mx-auto bg-[#bf2643]" />
								</div>
							))}
						</div>

						<div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-[80%] w-px mx-auto bg-[#bf2643] md:block hidden" />
					</div>
				</div>
			</div>
		</div>
	);
}
