import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { getStrapiUri } from "../../lib/get-strapi-uri";

const formSchema = z.object({
	name: z.string().trim().min(3, "Name is too short"),
	email: z.string().trim().email("Wrong email format"),
	project: z.string().trim().min(3, "Project name is too short"),
	message: z
		.string()
		.trim()
		.min(10, "Message is too short")
		.max(5000, "Message is too long"),
});

export function ContactForm() {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: "",
			email: "",
			project: "",
			message: "",
		},
	});

	const isSubmitting = form.formState.isSubmitting;

	const { errors } = form.formState;

	const [submitError, setSubmitError] = useState("");
	const [onSuccess, setOnSuccess] = useState("");

	async function onSubmit(values: z.infer<typeof formSchema>) {
		try {
			setSubmitError("");
			setOnSuccess("");

			const formData = new FormData();
			formData.append("data", JSON.stringify({ ...values }));

			await axios.post(getStrapiUri("/api/contact-requests"), {
				data: values,
			});

			setOnSuccess("Message sent successfully");

			form.reset();
		} catch (error) {
			setSubmitError("Something went wrong. Please try again later");
		}
	}

	return (
		<FormProvider {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="w-full max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 mt-8"
			>
				<Controller
					control={form.control}
					name="name"
					render={({ field }) => (
						<div>
							<input
								{...field}
								placeholder="Your name"
								type="name"
								autoComplete="name"
								disabled={isSubmitting}
								className="rounded-none border-b-2 border-[#555555] p-2 w-full bg-transparent focus:outline-none focus:border-white duration-200 transition-[border]"
							/>
							{errors.name && (
								<p className="text-red-600 text-sm">{errors.name.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="email"
					render={({ field }) => (
						<div>
							<input
								placeholder="Your email"
								{...field}
								type="email"
								autoComplete="email"
								disabled={isSubmitting}
								className="rounded-none border-b-2 border-[#555555] p-2 w-full bg-transparent focus:outline-none focus:border-white duration-200 transition-[border]"
							/>
							{errors.email && (
								<p className="text-red-600 text-sm">{errors.email.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="project"
					render={({ field }) => (
						<div className="md:col-span-2">
							<input
								{...field}
								placeholder="Project name"
								type="project"
								disabled={isSubmitting}
								className="rounded-none border-b-2 border-[#555555] p-2 w-full bg-transparent focus:outline-none focus:border-white duration-200 transition-[border]"
							/>
							{errors.project && (
								<p className="text-red-600 text-sm">{errors.project.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="message"
					render={({ field }) => (
						<div className="md:col-span-2">
							<textarea
								{...field}
								placeholder="Your message"
								rows={6}
								disabled={isSubmitting}
								className="rounded-none border-b-2 border-[#555555] p-2 w-full bg-transparent focus:outline-none focus:border-white duration-200 transition-[border]"
							/>
							{errors.message && (
								<p className="text-red-600 text-sm">{errors.message.message}</p>
							)}
						</div>
					)}
				/>

				{!!submitError && (
					<div className="md:col-span-2 text-center text-lg text-red-600">
						<p>{submitError}</p>
					</div>
				)}

				{!!onSuccess && (
					<div className="md:col-span-2 text-center text-lg">
						<p>{onSuccess}</p>
					</div>
				)}

				<div className="md:col-span-2 flex justify-center">
					<button
						type="submit"
						disabled={isSubmitting}
						className="text-sm font-bold uppercase py-4 rounded-t border-b-2 border-[#555555] text-[#808080]/80 hover:text-white hover:border-[#fb6884] duration-200"
					>
						{isSubmitting ? "SENDING..." : "SEND MESSAGE"}
					</button>
				</div>
			</form>
		</FormProvider>
	);
}
